<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 用户列表 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="手机号:">
                    <el-input v-model="queryParams.phone" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增用户</el-button>
            </div>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                <el-table-column label="头像" prop="avatar" align="center" width="200">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px" fit="cover"
                                  :class="`column${scope.$index}`" :src="scope.row.avatar"
                                  :preview-src-list="[scope.row.avatar]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="union平台ID" prop="union_id" align="center" width="120"></el-table-column>
                <el-table-column label="用户名" prop="username" align="center"></el-table-column>
                <el-table-column label="手机号" prop="phone" align="center" width="200"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">待审核</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">审核中</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">正常</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 4">删除</el-tag>
                        <el-tag type="info" v-if="scope.row.state === -1">已注销</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="ctime" align="center" width="200"></el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize"
                        v-on:refreshPageNum="refreshPageNum"/>
        </div>
        <!-- 新增用户 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="用户名" style="margin-top: 15px; margin-top: -10px;" prop="username">
                    <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="头像" prop="avatar">
                    <el-input v-model="form.avatar" placeholder="请输入头像链接"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false"
                               :on-success="handleAvatarSuccess" name="file" :before-upload="beforeAvatarUpload"
                               :headers="header" style="padding-top: 20px;">
                        <img v-if="form.avatar" :src="form.avatar" class="avatar" style="width: 100px; height: 100px;"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"
                           style="width: 100px;height: 100px; text-align: center;"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸1000*100像素</span>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <!-- <el-input type="tel" style="display: none;"></el-input> -->
                    <el-input v-model="form.phone" type="tel" autocomplete="off" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <!-- <el-input show-password style="display: none;"></el-input> -->
                    <el-input v-model="form.password" show-password autocomplete="off" style="padding: 0px,15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-select v-model="select" placeholder="请选择状态" class="handle-select mr10">
                        <el-option v-for="items in stateList" :key="items.ty" :label="items.name" :value="items.ty">
                        </el-option>
                    </el-select>
                </el-form-item>
              <el-form-item  label="union平台" prop="customer">
                <el-select v-model="form.union_id" placeholder="请选择union平台" clearable @change="selectParter">
                  <el-option v-for="(union,index) in unionList" :key="index" :label="union.name"
                             :value="union.id"></el-option>
                </el-select>
              </el-form-item>
                <el-divider content-position="right"></el-divider>
                <el-form-item label="合作伙伴/经销商">
                    <el-switch aria-label=""
                               v-model="form.is_partner"
                               active-text="是"
                               inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item v-if="form.is_partner" label="手机号查找" prop="consumption">
                    <el-input v-model="phone" placeholder="请输入完整手机号"></el-input>
                </el-form-item>
                <el-form-item v-if="form.is_partner" label="关联经销商" prop="customer">
                    <el-select v-model="form.uid" placeholder="请选择会员" clearable @change="selectParter">
                        <el-option v-for="(user,index) in userList" :key="index" :label="user.phone+':'+user.grade_name"
                                   :value="user.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="userTemp.user!=null" label="已关联" prop="customer">
                    <el-avatar :src="userTemp.avatar"></el-avatar>
                    <div>{{userTemp.user.id}}/{{userTemp.user.username}}/{{userTemp.user.nickname}}</div>
                    <div>{{userTemp.user.phone}}/{{userTemp.user.grade_name}}</div>
                </el-form-item>
                <el-divider content-position="right"></el-divider>
                <el-form-item>
                    <el-select
                        v-model="checkRoleIds"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选角色">
                        <el-option
                            v-for="item in roleList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import Picture from '@/components/Picture';
import {deal} from '@/common/main';
import loadEvents from '@/utils/loading'
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

let loads = new loadEvents();
export default {
    name: 'Users',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            loading: false,
            queryParams: {
                phone: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            form: {
                platform: 'admin',
                is_partner: false, //是不是合作伙伴
                is_super_admin: false, //是不是超级管理员
                uid:0,
                union_id:0,
            },
            pageInfo: {},
            addVisible: false,
            title: '新增管理员',
            userList: [],  // 查找出来的 用户列表
            phone: "",  // 要查找出来的用户 的手机号
            userTemp: {
                user:null
            }, //临时存储的用户
            // 验证
            rules: {
                avatar: [
                    {validator: deal.checkImage, trigger: ['blur', 'change'], required: false,}
                ],
                username: [{
                    required: true,
                    message: '用户名不能为空',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    trigger: "blur"
                },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur"
                    },
                    {
                        pattern: /^1[0-9]{10}$/,
                        message: "请输入正确的手机号码"
                    }
                ],
                /*  password: [{
                      required: true,
                      message: '密码不能为空',
                      trigger: 'blur'
                  }],*/
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            //状态
            stateList: [{
                ty: 0,
                name: '待审核'
            },
                {
                    ty: 1,
                    name: '审核中'
                },
                {
                    ty: 2,
                    name: '正常'
                },
                {
                    ty: 4,
                    name: '删除'
                },
                {
                    ty: -1,
                    name: '已注销'
                }
            ],
            select: 2,
            header: {},
            radio: '1',
            imageUrl: '',
            menuList: ['设置', '用户管理'],
            value: '',
            restaurants: [],
            input: '',
            text: '',
            checkRoleIds: [],
            roleList: [
                {}
            ],
            unionList: [{}]  ,// union 平台
        }
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'avatar', url);
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        //用户列表
        loadData() {
            this.loading = true;
            request.get('/user/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(9999, this.pageInfo)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //用户列表
        loadRoles() {
            request.get('/role/list', {page: 1, pagesize: 1000000, state: 2}).then(ret => {
                if (ret.code === 1) {
                    this.roleList = ret.data.list;
                    console.log(this.roleList)
                } else {
                    this.$message.error(ret.msg);
                }
            });

        },
       //union平台列表
        loadUnionList() {
          request.get('/system/union/list', {page: 1, pagesize: 1000000, state: 2}).then(ret => {
            if (ret.code === 1) {
              this.unionList = ret.data.list;
              console.log(this.unionList)
            } else {
              this.$message.error(ret.msg);
            }
          });

        },
        loadExistRoles(adminId) {
            request.get('/user/role/list', {"adminId": adminId}).then(ret => {
                if (ret.code === 1) {
                    let existRoles = ret.data.list;
                    this.checkRoleIds = []
                    for (const Key in existRoles) {
                        this.checkRoleIds.push(existRoles[Key].role_id)
                    }

                    console.log(this.checkRoleIds)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增用户
        add() {
            this.checkRoleIds = []
            this.mode = 'add';
            this.title = '新增用户';
            this.form = {};
            this.select = 0
            console.log(this.form)
            this.addVisible = true;

        },
        //编辑用户
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑用户';
            const item = this.pageInfo.list[index];
            this.form = Object.assign({}, item);
            if (this.form.is_partner === 1) {
                this.form.is_partner = true
            } else {
                this.form.is_partner = false
            }

            this.select = row.state
            this.$set(this.form, 'password', '');
            this.$set(this.form, 'id', row.id);
            this.loadExistRoles(row.id)
            this.addVisible = true;
            if (item.uid >0){
               this.getMemberDetail(item.uid)
            }
        },
        getMemberList(phone) {
            request.get('/account/list', {phone: phone}).then(ret => {
                if (ret.code === 1) {
                    this.userList = ret.data.list
                    if (this.userList.length === 0) {
                        this.$message.warning("未找到相关会员")
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        getMemberDetail(uid) {
            request.get('/account/detail', {id:uid}).then(ret => {
                if (ret.code === 1) {
                    this.userTemp = ret.data
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        selectParter(){
            for (const userKey in this.userList) {
                if (this.userList[userKey].id===this.form.uid){
                    this.userTemp=this.userList[userKey]
                }
            }
        },

        //新增，编辑用户
        saveData() {
            console.log(this.checkRoleIds)
            this.form.state = this.select
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/user/add' : '/user/edit';
                        //处理时间
                        let roleIds = JSON.stringify(this.checkRoleIds)
                        this.form.roleIds = roleIds
                        console.log(6666, this.form)
                        if (this.form.is_partner === true) {
                            this.form.is_partner = 1
                        } else {
                            this.form.is_partner = 0
                        }
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.addVisible = false;
                                this.loadData();
                                this.$refs.form.clearValidate()

                            } else {
                                this.addVisible = false;
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            }
        },
    },
    watch: {
        phone(now, old) {
            now = now.trim()
            if (now !== old && now.length === 11) {
                this.form.uid = 0  //清除之前绑定的
                this.getMemberList(now)
            }
        },
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        this.loadRoles();
        this.loadUnionList();
    }
}
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>



